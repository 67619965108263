import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import "../styles/postsHome.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const query = graphql`
  query {
    allStrapiPost(limit: 2, sort: { order: DESC, fields: published_at }) {
      edges {
        node {
          titulo
          slug
          categorias {
            nombre
          }
          extracto
          autor {
            Nombre
          }
          published_at
          imagen_destacada {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`

function convertirFecha(fecha) {
  return new Date(fecha).toLocaleDateString("es", {
    day: "numeric",
    month: "long",
    year: "numeric",
  })
}

export default function Postshome() {
  const data = useStaticQuery(query)

  return (
    <>
      <div className="caja-tarjetas">
        {data.allStrapiPost.edges.map(({ node }) => {
          const imagen = getImage(node.imagen_destacada.localFile)
          const slug = "/blog/" + node.slug + "/"
          // const slug = `blog/${node.slug}/`
          return (
            <article className="tarjeta-index" key={node.slug}>
              <GatsbyImage
                image={imagen}
                alt={node.imagen_destacada.alternativeText}
              />
              {/* <img 
                  src={`${process.env.API_URL}${node.imagen_destacada.formats.thumbnail.url}`}
                  alt={node.imagen_destacada.alternativeText}
                /> */}
              <div className="cuerpo-tarjeta">
                <div className="meta-tarjeta">
                  <span className="texto-fecha">
                    {convertirFecha(node.published_at)}
                  </span>
                  {/* <div className="categorias">
                    {node.categorias.map((value, index) => {
                      return <span key={index}>{value.nombre}</span>
                    })}
                    </div> */}
                </div>
                <h3>
                  <Link to={slug}>{node.titulo}</Link>
                </h3>
                <p className="extracto">{node.extracto}</p>
              </div>
              {/* <div className="meta-tarjeta">
                    <span className="texto-fecha">{convertirFecha(node.published_at)}</span>
                    <div className="categorias">
                    {node.categorias.map((value, index) => {
                      return <span key={index}>{value.nombre}</span>
                    })}
                    </div>
                </div> */}
            </article>
          )
        })}
      </div>
      <div className="enlace-blog">
        <Link className="link-under" to="/blog/">
          Ver el resto de entradas
        </Link>
      </div>
    </>
  )
}
