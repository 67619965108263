import React, { useEffect } from "react"
import Layout from "../components/layout"
import Postshome from "../components/postshome"
import Seo from "../components/SEO/Seo"
import { FaChevronDown } from "react-icons/fa"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Inicio = props => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const espc = window.matchMedia("(min-width: 768px)").matches

      if (espc) {
        gsap.registerPlugin(ScrollTrigger)

        gsap.utils.toArray(".pin").forEach((panel, i) => {
          ScrollTrigger.create({
            trigger: panel,
            start: "top top",
            pin: true,
            pinSpacing: false,
          })
        })
      }
    }
  })

  return (
    <Layout>
      <Seo
        title="Márketing y desarrollo web"
        pathname={props.location.pathname}
      />
      <section className="container cabecera-pag primera-seccion">
        <div className="caja-titulo">
          <h1>
            Una agencia <span className="subrayado">creativa</span>, potente y{" "}
            <span className="subrayado">cibersegura</span>
          </h1>
        </div>
        <div className="caja-subtitulo">
          <p className="destacado">
            {" "}
            Y es que nunca has visto nada igual. Una agencia creativa, con el
            foco puesto en la creación de experiencias digitales y con toda la
            potencia y ciberseguridad que nos da tener a nuestro lado el equipo
            de sistemas de bullhost.security
          </p>
          <Link className="link-cabecera link-under" to="/contacto/">
            Contacta
          </Link>
        </div>
        <a href="#proyectos-2" className="leer-mas" aria-label="Continuar">
          <FaChevronDown />
        </a>
      </section>
      <div id="pinMaster">
        <section className="container sect-proyectos" id="proyectos-2">
          <article
            className="proyecto proy-1 pin"
            data-texto="pensar"
            data-color="morado"
          >
            <div className="foto-proyecto">
              <StaticImage
                src="../../static/img/bombillas-1.jpg"
                alt="Hombre con un casco con bombillas"
              />
            </div>
            <div className="text-proyecto">
              <h3>
                <span className="subrayado">Pensar</span>
              </h3>
              <p>
                Inputs, inquietudes y necesidades que plantean los clientes, más
                la investigación del sector, sus productos y servicios, sus
                enfoques y los de la competencia: pensar en todo.
              </p>
              <Link className="link-under" to="/proyectos/">
                Ver proyectos
              </Link>
            </div>
          </article>
          <article
            className="proyecto proy-2 pin"
            data-color="naranja"
            data-texto="facilitar"
            id="proyecto-2"
          >
            <div className="foto-proyecto">
              <StaticImage
                src="../../static/img/facilitar-jetpack.jpg"
                alt="Mujer con un jetpack"
              />
            </div>
            <div className="text-proyecto">
              <h3>
                <span className="subrayado">Facilitar</span>
              </h3>
              <p>La imaginación no corre, sino vuela.</p>
              <p>
                Y volar es lo que más nos mola. Imaginar nos permite ser libres,
                no hay costuras ni límites que nos opriman. Convertir sueños en
                proyectos es fácil siempre que hayas sabido cómo perseguirlos.
              </p>
              <Link className="link-under" to="/proyectos/">
                Ver proyectos
              </Link>
            </div>
          </article>
          <article
            className="proyecto proy-3 pin"
            data-color="azul"
            data-texto="crear"
          >
            <div className="foto-proyecto">
              <StaticImage
                src="../../static/img/crear-caras.jpg"
                alt="Hombre con una serie de fotos de su cara"
              />
            </div>
            <div className="text-proyecto">
              <h3>
                <span className="subrayado">Crear</span>
              </h3>
              <p>
                Vivimos en la era de los “makers”, momento en el que eres lo que
                haces (no lo que dices que haces). Aquí construímos proyectos
                para que tu empresa y tú mostréis vuestra mejor cara. Y como tu
                cara, cada uno de nuestros proyectos es único.
              </p>
              <Link className="link-under" to="/proyectos/">
                Ver proyectos
              </Link>
            </div>
          </article>
          <article
            className="proyecto proy-4"
            data-color="verde"
            data-texto="comunicar"
          >
            <div className="foto-proyecto">
              <StaticImage
                src="../../static/img/comunicar.jpg"
                alt="Hombre bajo una lluvia de likes"
              />
            </div>
            <div className="text-proyecto">
              <h3>
                <span className="subrayado">Comunicar</span>
              </h3>
              <p>
                Trazamos contigo la estrategia de comunicación de tu empresa.
                Diseñar un mensaje efectivo (que llegue a quien queremos que
                llegue) y persuasivo para que, no sólo guste a tus clientes,
                sino que les emocione.
              </p>
              <Link className="link-under" to="/proyectos/">
                Ver proyectos
              </Link>
            </div>
          </article>
        </section>
      </div>
      <section className="container nosotros">
        <div className="col-left">
          <h2>
            Un <span className="subrayado">equipo</span> diferente en una
            agencia poco común
          </h2>
        </div>
        <div className="col-right">
          <p className="destacado">
            En Bullhost contamos con un equipo multidisciplinar capaz de
            ejecutar proyectos de branding, diseño web, aplicaciones y marketing
            digital que nunca habían sido tan técnicos, potentes y visibles. No
            todo es diseño y usabilidad en los proyectos digitales, deben ser
            seguros.
          </p>
          <p className="destacado">
            {" "}
            A través de la web o aplicaciones visibles en internet recoges datos
            confidenciales, vendes… ¿quieres ser hackeado? Seguro que no.
            Entonces esta es tu agencia y este es tu equipo.
          </p>
        </div>
      </section>
      <section className="entradas container">
        <h2>Últimos posts</h2>
        <Postshome />
      </section>
    </Layout>
  )
}

export default Inicio
